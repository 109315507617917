import React, { useMemo, useRef } from 'react';
import { Stack, Center, Frame, Split } from "@bedrock-layout/primitives"

import { useDownloadUrls, useOnlineStatus } from 'bythebook-shared/dist/firebase';
import { friendlyScriptureRef, useRefListener, valuesAfter } from 'bythebook-shared/dist/util';
import { useResourceContext } from 'bythebook-shared/dist/components';

export function MediaResults({setQuery, numResults=15}) {
  const {query, seriesList} = useResourceContext()

  // memoize filtering through all the videos for a list of similar videos for below
  const [similarVideos, thumbLocations] = useSimilarVideos(numResults)

  const thumbnails = useDownloadUrls(thumbLocations)

  return Object.keys(query).length > 0 && <Center maxWidth="50rem" >
    <Stack gutter="xl" >
      {similarVideos && thumbnails && similarVideos.map(([k, v], i) =>{
        return <VideoResult key={k} seriesList={seriesList} onClick={()=>setQuery({series:v.series, module:v.module})} videoKey={k} videoResource={v} thumbnail={thumbnails[i]} />
      })}
    </Stack>
  </Center>
}

function useSimilarVideos(numResults) {
  const {query, allResources, offlineResources} = useResourceContext()
  const online = useOnlineStatus()

  // memoize filtering through all the videos for a list of similar videos for below
  return useMemo(()=> {
    const validResources = online ? allResources : offlineResources

    let sameSeriesKeys = Object.entries(validResources)
      .filter( ([k, v]) => v.series===query.series).map(([k,v])=>k)
    sameSeriesKeys = valuesAfter(sameSeriesKeys, query.id, numResults/3)

    let sameModuleKeys = Object.entries(validResources)
      .filter( ([k, v]) => v.module===query.module).map(([k,v])=>k)
    sameModuleKeys = valuesAfter(sameModuleKeys, query.id, numResults/3)

    let keys = [...sameModuleKeys, ...sameSeriesKeys].filter(k=>k!=query.id)

    const extra = valuesAfter(Object.keys(validResources), query.id, numResults - keys.length)
      .filter(k => !keys.includes(k))
    keys = [...keys, ...extra]

    const similarVideos = keys.map(k=>[k, validResources[k]])
    const thumbLocations = similarVideos.map(([k,v]) => online ? v?.location : '')

    return [similarVideos, thumbLocations]
  }, [query, allResources, numResults, online])
}

function VideoResult(props) {
  const {videoKey, videoResource, thumbnail, seriesList, ...other} = props
  const {module, series, translation, title, creator, description} = videoResource

  const special = seriesList[series]?.format === 'generated' ? 'special' : ''
  const thumbnailRef = useRef()

  // Jump past the first frame, because it is often blank right now
  useRefListener(thumbnailRef, 'loadedmetadata', ()=>{
    thumbnailRef.current.currentTime = 10
  }, [videoKey])

  return <Split fraction="1/3" switchAt="20rem" gutter="lg" {...other}>
    <Frame ratio={[16,9]} className={'thumbnail ' + special}><video ref={thumbnailRef} muted src={thumbnail} /></Frame>
    <Stack gutter="sm" >
      <b className='optionTitle'>{friendlyScriptureRef(module)} {series} {title}</b>
      <p className='description'>{videoResource.description || seriesList[series]?.description || ''}</p>
    </Stack>
  </Split>
}
